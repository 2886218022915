import { useParams } from 'react-router-dom'
import { useState } from "react"
import { MorePost } from "../components/MorePost";
import { Footer } from "../components/HeaderFooter/Footer"
import "../style/Page.css"
import "./SearchResults.css"
import { wurl } from "../tools/url";
import { useEffect } from "react";
import axios from 'axios';

export function SearchResults() {
    let { query } = useParams();
    const [searchResults, setSearchResults] = useState([]);

    const getSearchResults = async () => {
        try {
            const response = await axios.get(`${wurl}/wp-json/wp/v2/search?search`, {
            params: { search: query, _embed: true },
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getSearchResults();
        //eslint-disable-next-line
    }, []);

    return(
        <div>
            <div className="content-wrap">
                <div className="post-wrapper">
                    <div className="search-frame">
                        <div className="top-text-wrapper">
                            <div className="rezultati-text">{`Resultati prertaživanja za "${query}":`}</div>
                        </div>
                        {
                        searchResults.length === 0 ?
                        (<div className="sorry">Žao nam je, nema rezultata koji odgovaraju upisanom pojmu. Molimo Vas da pokušate sa drugačijim ključnim riječima. </div>) :
                        (searchResults.map(result => (
                            <MorePost
                                key={result.id}
                                title={result.title}
                                id={result.id}
                            />
                        )))
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}