import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import "./HeaderDropdown.css"
import { useState } from "react"
import { IoMdArrowDropdown } from "react-icons/io";

export function HeaderDropdown(props) {
    const [highlight, setHighlight] = useState(false);
    const location = useLocation()

    const setHighlighted = () => {
        setHighlight(!highlight);
    };

    const unsetHighlighted = () => {
        setHighlight(false);
    };


    useEffect(() => {
        unsetHighlighted();
    }, [location.pathname]);

    return(
        <div 
            className="nav-item-container"
            onClick={setHighlighted}
            // onMouseEnter={setHighlighted}
            onMouseLeave={unsetHighlighted}
            >
            <div className="dropdown-title">
                <span className="navigation-main">{props.title}</span>
                <IoMdArrowDropdown />
            </div>
            <div 
                className={highlight ? `dropdown-list` : 'dropdown-list-hidden'}
                onClick={setHighlighted}
                // onMouseEnter={setHighlighted}
                onMouseLeave={unsetHighlighted}
                >
                <div className="dropdown-frame">
                    {props.list.map(link => {
                        return <Link 
                            to={link[0]}
                            key={link[0]}
                            >
                                {link[1]}
                            </Link>   
                    })}
                </div>
            </div>
        </div>
    )
}