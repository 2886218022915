import { useParams, useLocation } from 'react-router-dom'
import { useEffect, useState, useRef } from "react"
import { Footer } from '../components/HeaderFooter/Footer';
import uuid from 'react-uuid';
import { StreetFound } from '../components/StreetFound';
import Fuse from 'fuse.js';

export function Raspored() {
    let { type } = useParams();

    const location = useLocation()
    const [loading, setLoading] = useState(true);
    const data = useRef(null);
    const [formattedData, setFormattedData] = useState([]);
    const [formattedData24, setFormattedData24] = useState([]);

    const normalizedNames = useRef([]);
    const [searchStreet, setSearchStreet] = useState("");
    const [streetFound, setStreetFound] = useState([]);

    const normalizeText = (text) => {
        return text.toLowerCase()
            .split('đ').join('d')
            .split('ž').join('z')
            .split('š').join('s')
            .split('č').join('c')
            .split('ć').join('c')
            .split('.').join(' ')
            .split('-').join(' ')
    };

    const fuseOptions = {
        keys: ['cleanName'],
        threshold: 0.4,
        includeScore: true
    };

    const findIndex = (sname) => {
        let results = [];

        const fuse = new Fuse(data.current, fuseOptions);
        //results = fuse.search(sname).map(result => result.item);
        results = fuse.search(sname);

        // for (let i = 0; i < normalizedNames.current.length; i++) {
        //   if (normalizedNames.current[i].includes(sname)) {
        //     results.push(i + 1); // +1 zbog prvog null elementa
        //   }
        // }
        if (results.length > 0) {
            return results;
        }
        return -1; // Return -1 if substring not found in any string
    };

    const findStreet = (e) => {
        if (e.key === 'Enter') {
            if (searchStreet.length > 2) {
                const res = findIndex(normalizeText(searchStreet));
                if (res === -1) {
                    alert("Nije nađena nijedna ulica s tim imenom!")
                } else {
                    let resarray = [];
                    for (let i = 0; i < res.length; i++) {
                        //resarray.push(data.current[res[i]]);
                        resarray.push(res[i]);
                    };

                    setStreetFound(resarray);
                }
            } else {
                alert("Morate unjeti najmanje 3 slova!");
            }
          }
    };

    const updateStreetSearch = (e) => {
        setSearchStreet(e.target.value);
    };

    const dayWithMostStreetNames = (days) => {
        let maxLength = 0;
        let longest = null;
        for (let i = 0; i < days.length; i++) {
            if (days[i].length > maxLength) {
                maxLength = days[i].length;
                longest = days[i];
            }
        };

        return longest;
    };

    const formatForRendering = (fdays, fmaxLength) => {
        let formatted = [];
        for (let i = 0; i < fmaxLength.length; i++) {
            let row = [];
            if (fdays[0].length <= i) {
                row.push('');
            } else {
                row.push(fdays[0][i]);
            };
            if (fdays[1].length <= i) {
                row.push('');
            } else {
                row.push(fdays[1][i]);
            };
            if (fdays[2].length <= i) {
                row.push('');
            } else {
                row.push(fdays[2][i]);
            };
            if (fdays[3].length <= i) {
                row.push('');
            } else {
                row.push(fdays[3][i]);
            };
            if (fdays[4].length <= i) {
                row.push('');
            } else {
                row.push(fdays[4][i]);
            };
            formatted.push(row)
        };

        return formatted;
    };

    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    }, [location.pathname]);

    useEffect(() => {
        const fetchData = async () => {
            // try {
            //     const response = await axios.get(`${wurl}/wp-json/raspored/v1/jsondata`);
            //     data.current = response.data;
            //     console.log(data.current);
            // } catch (error) {
            //     console.log(error);
            // } finally {
            //     setLoading(false);
            // }

            try {
                const response = await fetch('https://komsb.silicabit.com/json/ulice.json');
                const streetData = await response.json();

                const searchData = streetData
                .filter((item) => item !== null)
                .map((item, index) => ({
                    id: item.id,  // Unique ID
                    displayName: item.displayName,
                    cleanName: item.cleanName,
                    coords: item.coords,
                    komunalni: item.komunalni,
                    korisni: item.korisni,
                    bio: item.bio
                }));


                data.current = searchData;
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(()=>{
        if (data.current !== null) {
            //console.log(data.current);
            // Sort street names per days of week
            let days = [[],[],[],[],[]];
            let days24 = [[],[],[],[],[]];
            let normalized = [];
            for (let i = 0; i < data.current.length; i++) {  
                if (data.current[i] !== null) {
                    switch(data.current[i][type]['dan']) {
                        case 1:
                            if (type !== 'korisni') {
                                days[0].push(data.current[i]['displayName']);
                            } else {
                                if (data.current[i]['korisni']['tjedan'][0] === 1) {
                                    days[0].push(data.current[i]['displayName']);
                                } else {
                                    days24[0].push(data.current[i]['displayName']);
                                }
                            }
                            break;
                        case 2:
                            if (type !== 'korisni') {
                                days[1].push(data.current[i]['displayName']);
                            } else {
                                if (data.current[i]['korisni']['tjedan'][0] === 1) {
                                    days[1].push(data.current[i]['displayName']);
                                } else {
                                    days24[1].push(data.current[i]['displayName']);
                                }
                            }
                            break;
                        case 3:
                            if (type !== 'korisni') {
                                days[2].push(data.current[i]['displayName']);
                            } else {
                                if (data.current[i]['korisni']['tjedan'][0] === 1) {
                                    days[2].push(data.current[i]['displayName']);
                                } else {
                                    days24[2].push(data.current[i]['displayName']);
                                }
                            }
                            break;
                        case 4:
                            if (type !== 'korisni') {
                                days[3].push(data.current[i]['displayName']);
                            } else {
                                if (data.current[i]['korisni']['tjedan'][0] === 1) {
                                    days[3].push(data.current[i]['displayName']);
                                } else {
                                    days24[3].push(data.current[i]['displayName']);
                                }
                            }
                            break;
                        default:
                            if (type !== 'korisni') {
                                days[4].push(data.current[i]['displayName']);
                            } else {
                                if (data.current[i]['korisni']['tjedan'][0] === 1) {
                                    days[4].push(data.current[i]['displayName']);
                                } else {
                                    days24[4].push(data.current[i]['displayName']);
                                }
                            }
                            break;
                    }
    
                    // Normalize street names for search
                    normalized.push(normalizeText(data.current[i]['displayName']));
                } else {
                    console.log(data.current[i]);
                }
            };

            const maxLength = dayWithMostStreetNames(days);
            const formatted = formatForRendering(days, maxLength);
            setFormattedData(formatted);

            if (type==='korisni') {
                const maxLength24 = dayWithMostStreetNames(days24);
                const formatted24 = formatForRendering(days24, maxLength24);
                setFormattedData24(formatted24);
            }
            normalizedNames.current = normalized;
        }
        //eslint-disable-next-line
    }, [loading]);

    return (
        <div>
            <div className="content-wrap">
                {!loading ? 
                    <div>
                        <div className="page-content">
                            <h1>Raspored odvoza za {type} otpad</h1>
                            <input type='text' placeholder='Nađi moju ulicu...' className='nadi-ulicu' value={searchStreet} onChange={updateStreetSearch} onKeyPress={findStreet}/>
                            {streetFound.length > 0 ? 
                                streetFound.map((street) => {
                                    return(
                                        <StreetFound
                                            name={street.displayName}
                                            bio={street.bio}
                                            komunalni={street.komunalni}
                                            korisni={street.korisni}
                                            key={uuid()}
                                        />
                                    )
                                })
                            : <div></div>}
                            {type !== 'korisni' ? (
                                <div className="table-overflow-wrapper">
                                    <table className='table'>
                                        <thead className='table-head'>
                                            <tr>
                                                <th>Ponedeljak</th>
                                                <th>Utorak</th>
                                                <th>Srijeda</th>
                                                <th>Četvrtak</th>
                                                <th>Petak</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-body'>
                                            {formattedData.map((row) => {
                                                return(
                                                    <tr 
                                                        className='table-row'
                                                        key={uuid()}
                                                    >
                                                        <td>{row[0]}</td>
                                                        <td>{row[1]}</td>
                                                        <td>{row[2]}</td>
                                                        <td>{row[3]}</td>
                                                        <td>{row[4]}</td>
                                                    </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) :
                                <div className="table-overflow-wrapper">
                                    <div className='tablice-u-mjesecu'>
                                            <table className='table'>
                                                <thead className='table-head'>
                                                    <tr>
                                                        <th>Prvi i treći ponedeljak u mjesecu:</th>
                                                        <th>Prvi i treći utorak u mjesecu:</th>
                                                        <th>Prva i treća srijeda u mjesecu:</th>
                                                        <th>Prvi i treći četvrtak u mjesecu:</th>
                                                        <th>Prvi i treći petak u mjesecu:</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='table-body'>
                                                    {formattedData.map((row) => {
                                                        return(
                                                            <tr 
                                                                className='table-row'
                                                                key={uuid()}
                                                            >
                                                                <td>{row[0]}</td>
                                                                <td>{row[1]}</td>
                                                                <td>{row[2]}</td>
                                                                <td>{row[3]}</td>
                                                                <td>{row[4]}</td>
                                                            </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                            <table className='table'>
                                                <thead className='table-head'>
                                                    <tr>
                                                        <th>Drugi i četvrti ponedeljak u mjesecu:</th>
                                                        <th>Drugi i četvrti utorak u mjesecu:</th>
                                                        <th>Druga i četvrta srijeda u mjesecu:</th>
                                                        <th>Drugi i četvrti četvrtak u mjesecu:</th>
                                                        <th>Drugi i četvrti petak u mjesecu:</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='table-body'>
                                                    {formattedData24.map((row) => {
                                                        return(
                                                            <tr 
                                                                className='table-row'
                                                                key={uuid()}
                                                            >
                                                                <td>{row[0]}</td>
                                                                <td>{row[1]}</td>
                                                                <td>{row[2]}</td>
                                                                <td>{row[3]}</td>
                                                                <td>{row[4]}</td>
                                                            </tr>
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                                }
                        </div>
                    </div>
                : null}
            </div>
            <Footer/>
        </div>
    );
}