import "./CustomZoom.css"

export function CustomZoom(props) {
    return(
        <div 
            className="zommed-in-image-background"
            onClick={props.dismiss}    
        >
            <img
                className="zommed-in-image"
                src={props.image}
                alt="osmrtnica"
                onClick={props.dismiss}
            />
        </div>
    )
}