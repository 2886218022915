import { useState } from "react"
import { IoMdTime } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import "./HomePost.css"
import he from 'he';
import { cutText } from "../tools/cutText";

export function HomePost(props) {
    const [hovered, setHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const openPost = () => {
        navigate(`/post/${props.id}`);
    };

    const title = he.decode(props.title);
    //const previewText = props.excerpt.replace(/<\/?[^>]+(>|$)/g, "");

    return(
        <div 
            className={`home-post-wrapper ${hovered ? 'hover-post' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseUp={openPost}
            >
            {
                props.image !== null ?
                <img src={props.image.props.src} alt='Post featured'/> :
                <img src={`media/${props.slug}.jpg`} alt='Post featured'/>
            }
            <div className={`news-content-wrapper`}>
                <h3>{cutText(title, 50)}</h3>
                <div className="time">
                    <span>{props.category}</span>
                    <IoMdTime />
                    <span>{Moment(props.date).format('DD.MM.YYYY.') }</span>
                </div>
                {/* <p>{cutText(previewText, 80)}</p> */}
            </div>
        </div>
    );
}