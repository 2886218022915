import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import "./Footer.css"
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";

export function Footer() {
    return(
        <footer>
            <div className="footer-top">
                <div>
                    <IoMdMail />
                    <a href="mailto:uprava@komunalac-sb.hr" className="info" target="_blank" rel="noreferrer">uprava@komunalac-sb.hr</a>
                </div>
                <div>
                    <FaPhone />
                    <a href="tel:+38535445603" className="info" target="_blank" rel="noreferrer">+ 385 (0)35 445 603</a>
                </div>
                <div>
                    <FaLocationDot />
                    <a href="https://maps.app.goo.gl/mnyquwhrR5z8FrGD6" className="info" target="_blank" rel="noreferrer">Stjepana pl. Horvata 38</a>
                </div>
            </div>
            <div className="footer-content">
                <div>
                    <span className="bold">Komunalac d.o.o.</span>
                    <span>Informacije/reklamacije:</span>
                    <span>0800 200 035</span>
                    <span>Stjepana pl. Horvata 38</span>
                    <span>HR-35000 Slavonski Brod</span>
                    <span>IBAN: HR72 2340009 1110885904, PBZ d.d.</span>
                </div>
                <div>
                    <span className="bold">Navigacija</span>
                    <Link 
                        to="page/646/9"
                        className="link"
                        >
                        Odluke
                    </Link>
                    <Link 
                        to="page/648/4"
                        className="link"
                        >
                        Javna nabava
                    </Link>
                    <Link 
                        to="page/650/5"
                        className="link"
                        >
                        Natječaji
                    </Link>
                    <Link 
                        to="page/652/7"
                        className="link"
                        >
                        Zapošljavanje
                    </Link>
                    
                </div>
                <div>
                    <span className="bold">Nađite nas</span>
                    <div className="icon-text">
                        <FaFacebookF />
                        <a href="https://www.facebook.com/people/Komunalac-Slavonski-Brod/100086974944619/" className="link" target="_blank" rel="noreferrer">Facebook</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}