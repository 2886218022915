import "./PostPageNavigation.css"

export function PostPageNavigation(props) {
    return(
        <div 
            className="post-page-navigation"
            onClick={props.click}    
        >
            {props.text}
        </div>
    )
}