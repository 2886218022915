import "./Search.css"
import { IoSearch } from "react-icons/io5";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export function Search(props) {
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [query, setQuery] = useState("");
    // const [searchResults, setSearchResults] = useState([]);
    const componentRef = useRef();

    const handleSearchText = (e) => {
        setQuery(e.target.value);
    };

    const searchText = () => {
        if (!query) return; // Don't perform the search if the query is empty
        navigate(`/searchresults/${query}`);
        // try {
        //     const response = await axios.get(`${wurl}/wp-json/wp/v2/search?search`, {
        //     params: { search: query, _embed: true },
        //     });
        //     setSearchResults(response.data);
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };

    // useEffect(()=>{
    //     if (active && query !== "") {
    //         navigate(`/searchresults/${query}`);
    //     }
    //     //eslint-disable-next-line
    // }, [searchResults])

    const handleKeyDown = (e) => {
        if (e.key==='Enter') {
            searchText();
        }
    };

    useEffect(() => {
        window.onclick = (event) => {
            if (event.target !== componentRef.current && !componentRef.current.contains(event.target)) {
                setActive(false);
                props.toggle(false);
            } else {
                setActive(true);
                props.toggle(true);
            }
        }
        //eslint-disable-next-line
    }, []);

    return(
        <div 
            className="search-wrapper">
            <div 
                className={active ? "search-component active" : "search-component"} 
                ref={componentRef}
                >
                <input 
                    type="text" 
                    className="search-text"
                    value={query}
                    onChange={handleSearchText}
                    onKeyDown={handleKeyDown}
                    placeholder='Traži ...'    
                />
                <IoSearch 
                    className="search-icon"   
                />
            </div>
        </div>
    );
}