import "./Header.css"
import { Link, useLocation } from "react-router-dom";
import { HeaderDropdown } from "./HeaderDropdown";
import { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { Search } from "./Search";

export function Header() {
    const [menuOpened, setMenuOpened] = useState(false);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation()

    const showMenu = () => {
        setMenuOpened(true);
    };

    const hideMenu = () => {
        setMenuOpened(false);
    };

    const toggleSearch = (s) => {
        setSearchExpanded(s);
    };

    useEffect(()=>{
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        hideMenu();
        toggleSearch(false);
    }, [location.pathname]);

    return(
        <header>
            <div className="header-content">
                <Link to="/">
                    <img src={windowWidth < 1000 ? '/media/komunalac_logo_small.png' : '/media/komunalac_logo.png'} alt='komunalac logo' className="logo-image"/>
                </Link>
                <div className="header-list">
                    {!searchExpanded ? 
                        <div  className={`navigation-links ${!menuOpened ? 'hidden' : ''}`}>
                            <Link 
                                to="/"
                                onMouseUp={hideMenu}
                                className="home home-naslovna"
                                >
                                Naslovna
                            </Link>
                            <HeaderDropdown
                                title="Novosti"
                                list={[["page/2449/3", "Obavijesti"], ["page/646/9", "Odluke"], ["page/648/4", "Javna nabava"], ["page/650/5", "Natječaji"], ["page/652/7", "Zapošljavanje"]]}
                                onMouseUp={hideMenu}
                            />
                            <HeaderDropdown
                                title="Usluge"
                                list={[["page/654", "Zelenilo i čistoća"], ["page/656", "Higijena"], ["page/658", "Reciklažno dvorište"], ["page/660/10", "Tržnica"], ["page/662/6", "Sklonište za napuštene životinje"], ["page/664", "Groblje"], ["https://egroblje.komunalac-sb.hr/#/index", "E-Groblje"], ["https://aksb.hr/", "Autobusni kolodvor"], ["/map", "Lokacije vozila"]]}
                                onMouseUp={hideMenu}
                            />
                            <HeaderDropdown
                                title="Izvješća"
                                list={[["page/666", "Cjenici usluga"], /*["page/668/8", "EU projekti"],*/ ["page/670", "Planovi i izvješća"], ["page/672", "Pravo na pristup informacijama"], ["page/674", "Dokumenti vezano uz zaštitu osobnih podataka"]]}
                                onMouseUp={hideMenu}
                            />
                            <HeaderDropdown
                                title="O nama"
                                list={[["page/676", "O nama"], ["page/678", "Povijest"], ["page/680", "Vizija i ciljevi"], ["page/682", "Opći podaci o tvrtki"], ["page/684", "Politika kvalitete"], ["page/686", "Opći akti društva"]]}
                                onMouseUp={hideMenu}
                            />
                            <Link 
                                to="/page/2825"
                                onMouseUp={hideMenu}
                                className="home"
                                >
                                Kompostana
                            </Link>
                            <Link 
                                to="/kontakt"
                                onMouseUp={hideMenu}
                                className="home"
                                >
                                Kontakt
                            </Link> 
                            <Link 
                                to="page/2673"
                                onMouseUp={hideMenu}
                                className="home"
                                >
                                Podnošenje prigovora
                            </Link> 
                        </div>
                        : null
                    }
                    <Search toggle={toggleSearch}/>
                </div>
                {
                    menuOpened ? <IoClose size={32} className="menu-button" onClick={hideMenu}/> : 
                    <GiHamburgerMenu size={32} className="menu-button" onClick={showMenu}/>
                }                
            </div>
        </header>
    )
}