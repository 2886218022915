import "./Share.css"

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
  } from "react-share";

export function Share(props) {
    return(
        <div className="share-container">
            <EmailShareButton url={props.url}>
                <EmailIcon size={32} round={false}/>
            </EmailShareButton>
            <FacebookShareButton url={props.url}>
                <FacebookIcon size={32} round={false}/>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={props.url}>
                <FacebookMessengerIcon size={32} round={false}/>
            </FacebookMessengerShareButton>
            <LinkedinShareButton url={props.url}>
                <LinkedinIcon size={32} round={false}/>
            </LinkedinShareButton>
            <ViberShareButton url={props.url}>
                <ViberIcon size={32} round={false}/>
            </ViberShareButton>
            <WhatsappShareButton url={props.url}>
                <WhatsappIcon size={32} round={false}/>
            </WhatsappShareButton>
            <RedditShareButton url={props.url}>
                <RedditIcon size={32} round={false}/>
            </RedditShareButton>
            <TelegramShareButton url={props.url}>
                <TelegramIcon size={32} round={false}/>
            </TelegramShareButton>
            <TwitterShareButton url={props.url}>
                <XIcon size={32} round={false}/>
            </TwitterShareButton>
        </div>
    )
}